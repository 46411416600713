import styled from 'styled-components'
import { theme } from 'styles/theme'
import ChatItemTitle from 'components/organisms/gpt-chat-title'
import ChatItemParagraph from 'components/organisms/gpt-chat-content'
import ChatItemKeys from 'components/organisms/gpt-chat-keys'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 20% 1fr;
  grid-gap: 20px;
  height: 100%;
  padding: 20px;
`

export const Title = styled.h1`
  font-size: 25px;
  color: ${theme.colors.white};
`

export const TitleChat = styled(ChatItemTitle)``

export const ContentChat = styled(ChatItemParagraph)``

export const KeysArea = styled(ChatItemKeys)``
