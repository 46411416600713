import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { PropsView } from './types'

const View = ({ value, response, handleSubmit, isLoading, ...props }: PropsView) => {
  const { t } = useTranslation()
  return (
    <S.TitleChat
      value={value}
      response={response}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      placeholder={t('titlePlaceholder') || ''}
      {...props}
    />
  )
}

export default View
