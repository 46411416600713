import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { PropsView } from './types'

const View = ({ value, handleChange, isLoading, keys, ...props }: PropsView) => {
  const { t } = useTranslation()
  console.log('HOLAS', keys)
  return (
    <S.Container>
      <S.KeysInput
        value={value}
        onChange={handleChange}
        isLoading={isLoading}
        placeholder={t('keysPlaceholder') || ''}
        {...props}
      />
      <S.Keys>
        {keys?.map(item => (
          <S.Key key={item}>{item}</S.Key>
        ))}
      </S.Keys>
      {}
    </S.Container>
  )
}

export default View
