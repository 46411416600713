export const theme = {
  colors: {
    black: '#000000',
    blue: '#004571',
    orange: '#E57200',
    white: '#FFFFFF',
    lightGray: '#FAFAFA',
    green: '#6ECE72',
    blueLight: '#E5ECF0',
  },
  gradients: {
    blue: 'linear-gradient(-105deg, #009acc, #363795)',
  },

  sizes: {
    header: '60px',
    sideMenu: '240px',
  },
}
