import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { ThemeProvider } from '@mui/material'
import 'styles/reset.css'

import 'assets/i18n'
import Routes from 'components/system/routes'
import muiTheme from 'styles/mui-theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <ThemeProviderStyled theme={muiTheme}>
          <Routes />
        </ThemeProviderStyled>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
