import styled from 'styled-components'
import { theme } from 'styles/theme'
import ChatItem from 'components/molecules/chat-item'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20% 1fr;
  grid-gap: 20px;
  height: 100%;
  padding: 20px;
`

export const Title = styled.h1`
  font-size: 25px;
  color: ${theme.colors.white};
`

export const TitleChat = styled(ChatItem)``

export const ContentChat = styled(ChatItem)``
