import styled from 'styled-components'
import HeaderComp from 'components/organisms/header'

import { theme } from 'styles/theme'

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: auto 1fr;
  height: 100vh;
  background-image: ${theme.gradients.blue};
  font-family: Verdana, sans-serif;
`

export const Header = styled(HeaderComp)`
  grid-area: header;
`

export const Content = styled.div`
  grid-area: content;
`
