import { Route, Routes } from 'react-router-dom'
import 'assets/i18n'
import Chat from 'components/pages/chat'
import Layout from 'components/system/layout'
const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<Chat />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
