import { useState } from 'react'
import View from './view'

const Chat = ({ ...props }) => {
  const [keys, setKeys] = useState([''])
  const handleChangeKeys = (value: string[]) => {
    setKeys(value)
  }
  return <View {...props} onChangeKeys={handleChangeKeys} keys={keys} />
}

export default Chat
