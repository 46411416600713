import { Outlet } from 'react-router-dom'
import * as S from './styled'

const Layout = () => (
  <S.Container>
    <S.Header />
    <S.Content>
      <Outlet />
    </S.Content>
  </S.Container>
)

export default Layout
