import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

import { theme } from 'styles/theme'

export const Textarea = styled(TextField)`
  && {
    .MuiInputBase-input {
      overflow: auto !important;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  & {
    .MuiInputBase-root {
      background-color: ${theme.colors.white};
      min-height: 100%;
      font-size: 16px;
      line-height: 23px;
    }
  }
`
