import { useState } from 'react'
import View from './view'
import useChatGptContent from 'hooks/use-chatgpt-content'
import { fetchChatTitleResponse } from 'model/interfaces/chat-gpt'
import * as Keys from './types'

const GptChatComponent = ({ keys }: Keys.Props) => {
  const [response, setResponse] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleSuccess = ({ data }: fetchChatTitleResponse) => {
    setResponse({ ...data })
    setIsLoading(false)
  }

  const handleError = () => {
    setIsLoading(false)
  }

  const handleFinish = () => {
    setIsLoading(false)
  }

  const mutation = useChatGptContent({
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: handleFinish,
  })

  const handleSubmit = (text: string) => {
    setIsLoading(true)
    mutation.mutate({ text, keys })
  }

  return <View response={response} handleSubmit={handleSubmit} isLoading={isLoading} />
}

export default GptChatComponent
