import { useMutation } from '@tanstack/react-query'

import { fetchChatTitle } from 'api'
import { fetchChatTitleProps } from 'model/interfaces/chat-gpt'

const useChatGptTitle = ({ ...props }) => {
  const mutation = useMutation({
    mutationFn: (props: fetchChatTitleProps) => {
      return fetchChatTitle(props)
    },
    ...props,
  })
  return { ...mutation }
}

export default useChatGptTitle
