import { createTheme } from '@mui/material'

import { theme } from './theme'

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.blue,
    },

    secondary: {
      main: theme.colors.orange,
    },
  },
  typography: {
    fontFamily: ['Roboto', '"FS Emeric"'].join(','),
    h1: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 65,
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 48,
      fontWeight: 250,
    },
    h3: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 36,
      fontWeight: 700,
    },
    h5: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 18,
      fontWeight: 700,
      alignItems: 'center',
      textAlign: 'center',
    },
    body1: {
      fontFamily: '"FS Emeric"',
      color: theme.colors.blue,
      fontSize: 14,
      fontWeight: 300,
    },
  },
})

export default muiTheme
