import axios, { AxiosResponse, AxiosError } from 'axios'
import { fetchChatParagraphProps, fetchChatContentProps, fetchChatTitleProps } from 'model/interfaces/chat-gpt'

import { BASE_URL } from 'config/api'

import * as logger from './logger'

export const aInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-type': 'application/json' },
})

aInstance.interceptors.request.use(
  config => {
    logger.logCall(config.method, config.url, config)
    return config
  },
  (e: AxiosError) => {
    logger.logError(e)
    return Promise.reject(e)
  }
)

aInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    logger.logResponse(res.config?.method, res.config?.url, res)
    return res
  },
  (e: AxiosError) => {
    logger.logError(e)
    return Promise.reject(e)
  }
)

export const fetchChatParagraph = ({ text, keys }: fetchChatParagraphProps): Promise<AxiosResponse> => {
  const url = `/gpt/request-paragraph`
  return aInstance.post(url, {
    text,
    keys,
  })
}

export const fetchChatTitle = ({ text }: fetchChatTitleProps): Promise<AxiosResponse> => {
  const url = `/gpt/request-title`
  return aInstance.post(url, {
    text,
  })
}

export const fetchChatContent = ({ text, keys }: fetchChatContentProps): Promise<AxiosResponse> => {
  const url = `/gpt/request-content`
  return aInstance.post(url, {
    text,
    keys,
  })
}
