export const splitKeys = (textStr: string) => {
  const keys = textStr.split(',')
  const newKeys: string[] = []
  keys.forEach(item => {
    const hasLength = item.replace(/\s/g, '').length > 0
    if (hasLength) newKeys.push(item)
  })
  console.log('HOLAasdasdasS', newKeys)
  return newKeys
}
