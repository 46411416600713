import styled from 'styled-components'
import Textarea from 'components/atoms/textarea'

export const KeysInput = styled(Textarea)`
  && {
    height: 100%;
    .MuiInputBase-root {
      height: 100%;
      padding-bottom: 38px;
      padding-top: 10px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Keys = styled.div`
  position: absolute;
  bottom: 7px;
  left: 7px;
  display: flex;
  gap: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 14px);
`

export const Key = styled.span`
  padding: 3px 10px;
  background-color: #3497c330;
  border-radius: 7px;
`
