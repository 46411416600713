import { useEffect, useState } from 'react'
import * as Types from './types'
import View from './view'
import { splitParagraph } from './utils'
import useChatGptParagraph from 'hooks/use-chatgpt-paragraph'

type responseState = {
  intro?: string
  text: string[]
}

const ChatItem = ({ value, response, onSubmit, isLoading, refactorType, placeholder, ...props }: Types.Props) => {
  const [valueTextarea, setValueTextarea] = useState(value)
  const [responseValue, setResponseValue] = useState<responseState>({ text: [], intro: '' })
  const [itemIsLoading, setItemIsLoading] = useState(false)

  useEffect(() => {
    const newResponse = {
      intro: response?.intro,
      text: splitParagraph(response?.text || ''),
    }
    setResponseValue(newResponse)
  }, [response])

  const handleChange = (e: any) => {
    setValueTextarea(e.target.value)
  }

  const handleSubmit = () => {
    onSubmit ? onSubmit(valueTextarea || '') : null
  }

  const handleSuccess = ({ data }: any, { index }: any) => {
    const newResponse = { ...responseValue }
    newResponse.text[index] = data?.text
    setResponseValue(newResponse)
    setItemIsLoading(false)
  }

  const handleError = () => {
    setItemIsLoading(false)
  }

  const handleFinish = () => {
    setItemIsLoading(false)
  }

  const mutation = useChatGptParagraph({
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: handleFinish,
  })

  const handleReloadParagraph = (index: number) => {
    setItemIsLoading(true)
    mutation.mutate({ text: responseValue?.text[index] || '', keys: [], index })
  }

  return (
    <View
      value={valueTextarea}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isLoading={isLoading || itemIsLoading}
      intro={responseValue?.intro}
      response={responseValue?.text}
      refactorType={refactorType}
      onReloadParagraph={handleReloadParagraph}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default ChatItem
