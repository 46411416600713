import { styled } from '@mui/material/styles'
import ButtonComp from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { theme } from 'styles/theme'

interface ButtonProps {
  loading?: boolean
}

export const Button = styled(ButtonComp)<ButtonProps>`
  && {
    border: solid 1px ${theme.colors.white};
    color: white;
    & svg {
      color: white;
    }
  }
`

export const Spinner = styled(CircularProgress)`
  && {
    width: 15px !important;
    height: 15px !important;
  }
`
