import styled from 'styled-components'
import TextareaComp from 'components/atoms/textarea'
import ButtonComp from 'components/atoms/button'
import { Reload } from 'assets/icons'

export const Container = styled.div<{ isLoading?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 169px 1fr;
  align-items: center;
  grid-gap: 20px;
`

export const Textarea = styled(TextareaComp)`
  & {
    height: 100%;
    width: 100%;
    & .MuiInputBase-root {
      height: 100%;
      width: 100%;
      padding: 0px;
      & .MuiInputBase-input {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
`

export const ParagraphListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 20px;
  justify-items: stretch;
  padding-right: 10px;
`
interface ParagraphListWrapperProps {
  isLoading?: boolean
}

export const ParagraphListWrapper = styled.div<ParagraphListWrapperProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  opacity: ${({ isLoading }) => (isLoading ? '0.4' : '1')};
`

export const Paragraph = styled.div`
  padding: 10px;
  padding-right: 31px;
  font-size: 16px;
  padding: 20px 31px 20px 20px;
  line-height: 23px;
  font-family: 'FS Emeric';
  font-weight: 200;
`

export const Button = styled(ButtonComp)``

export const ContentArea = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
`

export const ParagraphContainer = styled.div`
  position: relative;
  width: 100%;
`

export const ReloadButton = styled(Reload)`
  position: absolute;
  right: 5px;
  bottom: 50%;
  transform: translateY(-50%)
  width: 20px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
`
