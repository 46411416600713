import { useTranslation } from 'react-i18next'
import * as S from './styled'
import * as Types from './types'
import { REFACTOR_TYPES } from './utils'

const RenderResponse = ({
  refactorType,
  response,
  isLoading,
  intro,
  onReloadParagraph,
  placeholder,
}: Types.PropsResponse) => {
  if (refactorType === REFACTOR_TYPES.paragraph && response?.length) {
    return (
      <S.ParagraphListWrapper isLoading={isLoading}>
        <S.ParagraphListContainer>
          <S.ParagraphContainer>
            <S.Paragraph>{intro}</S.Paragraph>
          </S.ParagraphContainer>
          {response.map((paragraph, index) => (
            <S.ParagraphContainer key={paragraph}>
              <S.Paragraph key={paragraph}>{paragraph}</S.Paragraph>
              {onReloadParagraph && !isLoading && paragraph.length > 0 && (
                <S.ReloadButton onClick={() => onReloadParagraph(index)} />
              )}
            </S.ParagraphContainer>
          ))}
        </S.ParagraphListContainer>
      </S.ParagraphListWrapper>
    )
  }
  return (
    <S.ParagraphListWrapper isLoading={isLoading}>
      <S.Paragraph>{response ? response[0] : ''}</S.Paragraph>
    </S.ParagraphListWrapper>
  )
}

const View = ({
  value,
  response,
  handleSubmit,
  isLoading,
  handleChange,
  onReloadParagraph,
  refactorType,
  intro,
  placeholder,
  ...props
}: Types.PropsView) => {
  const { t } = useTranslation()
  return (
    <S.Container {...props} isLoading={isLoading}>
      <S.ContentArea>
        <S.Textarea value={value} disabled={isLoading} onChange={handleChange} placeholder={placeholder} />
      </S.ContentArea>

      <S.Button onClick={handleSubmit} isLoading={isLoading}>
        {t('reWrite')}
      </S.Button>
      <S.ContentArea>
        <RenderResponse
          response={response}
          refactorType={refactorType}
          isLoading={isLoading}
          onReloadParagraph={onReloadParagraph}
          intro={intro}
          placeholder={placeholder}
        />
      </S.ContentArea>
    </S.Container>
  )
}

export default View
