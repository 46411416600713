import * as S from './styled'

interface Props {
  isLoading?: boolean
  startIcon?: any
  children?: any
  onClick?: (e: any) => void
}

const Button = ({ isLoading, startIcon, ...props }: Props) => (
  <S.Button loading={isLoading} disabled={isLoading} startIcon={isLoading ? <S.Spinner /> : startIcon} {...props} />
)
export default Button
