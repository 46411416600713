import { useMutation } from '@tanstack/react-query'

import { fetchChatContent } from 'api'
import { fetchChatParagraphProps } from 'model/interfaces/chat-gpt'

const useChatGpt = ({ ...props }) => {
  const mutation = useMutation({
    mutationFn: (props: fetchChatParagraphProps) => {
      return fetchChatContent(props)
    },
    ...props,
  })
  return { ...mutation }
}

export default useChatGpt
