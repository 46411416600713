import styled from 'styled-components'
import { theme } from 'styles/theme'

export const Container = styled.div``

export const Title = styled.h1`
  font-size: 25px;
  color: ${theme.colors.white};
  padding: 20px;
`
