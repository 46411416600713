import * as S from './styled'
import { useTranslation } from 'react-i18next'

const View = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <S.Container {...props}>
      <S.Title>{t('title')}</S.Title>
    </S.Container>
  )
}

export default View
