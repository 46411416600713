import * as S from './styles'
import * as Types from './types'

const AppView = ({ onChangeKeys, keys }: Types.PropsView) => {
  return (
    <S.Container>
      <S.KeysArea onChange={onChangeKeys} />
      <S.TitleChat />
      <S.ContentChat keys={keys} />
    </S.Container>
  )
}

export default AppView
