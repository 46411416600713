import { useState } from 'react'
import View from './view'
import * as Types from './types'
import { splitKeys } from './utils'

const GptChatKeys = ({ onChange, isLoading }: Types.Props) => {
  const [value, setValue] = useState('')
  const keys = splitKeys(value)

  const handleChange = (e: any) => {
    const newKeys = splitKeys(e.target.value)
    setValue(e.target.value)
    onChange(newKeys)
  }

  return <View handleChange={handleChange} isLoading={isLoading} value={value} keys={keys} />
}

export default GptChatKeys
